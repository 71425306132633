import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404" />
    <div style={{ textAlign: 'center' }}>
      <h1>Well this is embarassing...</h1>
      <p>You just hit a route that does not exist... the sadness.</p>
      <Link to="/">Click here to go back!</Link>
    </div>
  </>
)

export default NotFoundPage
